import React, {useState} from "react";
import {Link} from "react-router-dom";
import {useForm} from "react-hook-form";

import Header from "../header";
import Footer from "../footer";
import {useGetUserQuery, useUpdateUserMutation} from "../../redux/rtk/api-rtk";

// Вынесение формы редактирования в отдельный компонент
const UserEditForm = ({user, onSubmit, onCancel}) => {

    const {register, handleSubmit, setValue} = useForm({
        defaultValues: {
            name: user?.data?.name || "",
            lastname: user?.data?.lastname || "",
          //  address: user?.data?.address || "",
            phone: user?.data?.phone || "",
            email: user?.data?.email || "",
        },
    });

    const [errors, setErrors] = useState({}); // Локальное состояние для ошибок

    const handleInputChange = (field) => () => {
        // Сброс ошибки для конкретного поля при вводе данных
        if (errors[field]) {
            setErrors((prevErrors) => ({...prevErrors, [field]: null}));
        }
    };

    const handleFormSubmit = async (data) => {
        try {
            await onSubmit(data);
        } catch (err) {
            // Сохраняем ошибки в локальное состояние
            if (err.data && err.data.errors) {
                setErrors(err.data.errors);
            } else {
                setErrors({general: ["Произошла ошибка при обновлении данных."]});
            }
        }
    };

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="edit__about_person">
                <div>
                    {["name", "lastname", "phone", "email"].map((field) => (
                        <label key={field}>
                            {field === "phone" ? "Телефон"
                                : field === "email" ? "Электронная почта"
                                  //  : field === "address" ? "Адрес"
                                        : field === "lastname" ? "Фамилия"
                                            : "Имя"}:
                            <input
                                type={field === "email" ? "email" : field === "phone" ? "tel" : "text"}
                                {...register(field, {required: true})}
                                onChange={handleInputChange(field)} // Сброс ошибки при вводе
                            />
                            {errors[field] && (
                                <p style={{color: "red"}}>{errors[field]}</p>
                            )}
                        </label>
                    ))}
                </div>
                <div>
                    <input type="submit" value="Подтвердить"/>
                    <button type="button" onClick={onCancel}>Отмена</button>
                </div>
            </div>
        </form>
    );
};
const PrivacyInfo = ({headerNav, footerNav, isLogged, authUser, handleLogOut}) => {
    const [stateEditor, openEditor] = useState(false);
    const {data: user, isLoading} = useGetUserQuery();
    const [updateUser] = useUpdateUserMutation();

    const handleOpenEditor = () => openEditor(true);
    const handleCloseEditor = () => openEditor(false);

    const onSubmit = async (data) => {
        try {
            await updateUser(data).unwrap();
            console.log("Данные успешно обновлены!");
            handleCloseEditor();
        } catch (err) {
            console.error("Ошибка при обновлении данных:", err);
            throw err; // Пробрасываем ошибку в компонент формы
        }
    };

    return (
        <>
            <Header headerNav={headerNav} isLogged={isLogged} authUser={authUser} handleLogOut={handleLogOut}/>
            <section className="personal_area">
                <nav>
                    <ul>
                        <Link to={`/personal-area/info`} className="private active">
                            <li>Личная информация</li>
                        </Link>
                        <Link to={`/personal-area/orders`} className="orders">
                            <li>Заказы</li>
                        </Link>
                        <Link to={`/personal-area/terms`} className="agreement">
                            <li>Условия обслуживания</li>
                        </Link>
                    </ul>
                </nav>
                {isLoading ? (
                    <div className="loader">Загрузка...</div>
                ) : stateEditor ? (
                    <UserEditForm
                        user={user}
                        onSubmit={onSubmit}
                        onCancel={handleCloseEditor}
                    />
                ) : (
                    <div className="personal_info">
                        <div className="about_person">
                            <div className="person_name">
                                <p>{user?.data?.name} {user?.data?.lastname}</p>
                                <p>Адрес: <span>{user?.data?.address || "Неизвестно"}</span></p>
                                <p>Телефон: <span>{user?.data?.phone || "Неизвестно"}</span></p>
                                <p>Электронная почта: <span>{user?.data?.email || "Неизвестно"}</span></p>
                                <button onClick={handleOpenEditor}></button>
                            </div>
                        </div>
                    </div>
                )}
            </section>
            <Footer footerNav={footerNav}/>
        </>
    );
};


export default PrivacyInfo;
