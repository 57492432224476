import React from 'react';

const Accordion = ({city, value, mapCenter, zoom, selected, shops, handleCityClick, handleShopClick}) => {
    return (
        <div className='contacts-accordion'>
            <button
                className={selected ? 'active' : ''}
                onClick={(e) => {
                    handleCityClick(e)
                }}
                data-value={value}
                data-zoom={zoom}
                data-center={mapCenter}
            >
                г. {city}
            </button>
            {selected && shops.map((shop, i) => (
                <div style={{cursor: 'pointer'}} className='addresses' key={i} onClick={(e) => {
                    handleShopClick(shop)
                }}>
                    <p>{shop.title}</p>
                    <p>{shop.phone}</p>
                </div>
            ))}
        </div>
    );
};

export default Accordion;