import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {loadCartFromStorage} from "../redux/slices/cartSlice";
import {useDispatch, useSelector} from "react-redux";
import {useGetUserQuery} from "../redux/rtk/api-rtk";

const Header = ({headerNav, isLogged, authUser, handleLogOut, location}) => {
    const [authMenu, setAuthMenu] = useState(false);
    const [stateHamburgerMenu, setStateHamburgerMenu] = useState(false);

    const {data: userData} = useGetUserQuery();

    const dispatch = useDispatch();
    const total = useSelector((state) => state.cart.total);

    useEffect(() => {
        dispatch(loadCartFromStorage());
    }, [dispatch]);

    const openAuthMenu = () => {
        setAuthMenu(!authMenu);
    };

    const handleHamburgerButtonClick = () => {
        setStateHamburgerMenu(!stateHamburgerMenu);
    };

    const handleNavClick = (link) => {
        if (link === "Каталог") {
           // localStorage.removeItem('lastSelectedCategoryKey'); // Очищаем ключ при размонтировании
           // localStorage.removeItem('openCategories'); // Очищаем состояние открытых категорий
            sessionStorage.removeItem('lastSelectedCategoryKey'); // Очищаем ключ при размонтировании
            sessionStorage.removeItem('openCategories'); // Очищаем состояние открытых категорий
        }
        setStateHamburgerMenu(false);
    };

  //  console.log('authUser', authUser)

    return (
        <header>
            <div className='header__wrapper'>
                <div className='header_mobile'>
                    <Link to='/'><img src='/images/logo.svg' alt='logo'/></Link>
                    <button className={`hamburger-button ${stateHamburgerMenu ? 'open' : ''}`}
                            onClick={handleHamburgerButtonClick}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                </div>
                <div className={`nav__wrapper ${stateHamburgerMenu ? 'active' : ''}`}>
                    <nav>
                        <ul>
                            {headerNav.map((item, i) => (
                                <Link
                                    to={item.path}
                                    className={location && location.pathname.includes(item.path) ? 'active' : ''}
                                    onClick={() => handleNavClick(item.name)}
                                    key={i}
                                >
                                    <li>{item.name}</li>
                                </Link>
                            ))}
                        </ul>
                    </nav>
                    <div className='header_buttons'>
                        {
                            isLogged ?
                                <>
                                    <button className='authorization_button' onClick={openAuthMenu}>
                                        {userData && `${userData?.data?.name} ${userData?.data?.lastname}` }
                                        {/*{authUser?.name ? `${authUser.name} ${authUser.lastname || ''}` : authUser?.login}*/}
                                    </button>
                                    {authMenu &&
                                        <div className='authorization_menu'>
                                            <Link to={`/personal-area/info`}
                                                  className='authorization_button'>Перейти в личный кабинет</Link>
                                            <button onClick={() => handleLogOut()}>Выйти из аккаунта</button>
                                        </div>
                                    }
                                </>
                                :
                                <Link to='/login' className='authorization_button'>Вход</Link>
                        }
                        <Link className='cart_button' to={'/cart'}>{total.toFixed(2)} руб</Link>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
