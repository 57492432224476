import {Route, Routes} from "react-router-dom";

import Header from "./components/header";
import Main from "./components/main";
import Footer from "./components/footer";

import Catalog from "./components/catalog/catalog";
import BrandCatalog from "./components/catalog/brand-catalog";
import ProductCart from "./components/catalog/product-cart";

import Contacts from "./components/contacts/contacts";
import TermsOfUse from "./components/terms-of-use/terms-of-use";
import NewsPage from "./components/news/news";
import ModelCatalog from "./components/catalog/model-catalog";
import {SearchResults} from "./components/catalog/SearchResults/SearchResults";
import {Cart} from "./components/cart/cart";

const Wrapper = (props) => {

    return (
        <>
            <Header {...props} />
            <Routes>
                <Route path='/' element={<Main/>}></Route>
                <Route path='/catalog' element={<Catalog {...props} />}/>
                <Route path='/catalog/:brand' element={<BrandCatalog {...props} />}/>
                <Route path='/catalog/:brand/:model' element={<ModelCatalog {...props} />}/>
                <Route path='/catalog/product/*' element={<ProductCart {...props} />}/>
                <Route path='/catalog/search/*' element={<SearchResults {...props} />}/>
                <Route path='/contacts' element={<Contacts stores={props.stores}/>}/>
                <Route path='/guarantee' element={<TermsOfUse/>}/>
                <Route path='/cart' element={<Cart/>}/>
                <Route path='/news/*' element={<NewsPage news={props.news}/>}/>
            </Routes>
            <Footer footerNav={props.footerNav}/>
        </>
    )
}

export default Wrapper;
