import React from "react";
import {Link, Navigate} from 'react-router-dom';

import SearchForm from "../main/search-form";
import {useDispatch, useSelector} from "react-redux";
import {getChosenBrand, getModels, setSelectedBrand} from "../../redux/slices/catalogSlice";
import Lottie from "lottie-react";
import spinner from '../../assets/spinner.json';
import {useLoadBrandsQuery} from "../../redux/rtk/api-rtk";


const Catalog = () => {
    const dispatch = useDispatch();
    const { data, isLoading, error } = useLoadBrandsQuery();
    const brand = localStorage.getItem("brand");

    // Если brand есть, перенаправляем на /catalog/:brand
    // if (brand) {
    //     dispatch(setSelectedBrand(brand))
    //     dispatch(getModels(brand))
    //     return <Navigate to={`/catalog/${brand}`} replace />;
    // }

    if (isLoading) {
        return <Lottie className='animation' animationData={spinner} loop={true} />;
    }

    if (error) {
        console.error('Ошибка при загрузке брендов:', error);
        return <p>Ошибка при загрузке брендов. Пожалуйста, повторите попытку.</p>;
    }

    const onclickChosenBrandHandler = (item) => {
        localStorage.setItem('brand', item.brand);
        dispatch(getChosenBrand({chosenBrand: item}));
        dispatch(setSelectedBrand(item.brand))
        dispatch(getModels(item.brand))
    }

    return (
        <main>
            <SearchForm/>
            <div className='catalog'>
                {data && data.brands.map((item, i) => (
                    <Link
                        to={`/catalog/${item.brand}`}
                        className='catalog-product'
                        onClick={() => onclickChosenBrandHandler(item)}
                        key={i}
                    >
                        <div className="card">
                            <div className='image-wrapper'>
                                <img className='logo-card-image' src='/images/default-icon-product.svg'
                                     alt='brand-logo'/>
                            </div>
                            <h3>
                                {item.brand}
                            </h3>
                        </div>
                    </Link>
                ))}
            </div>
        </main>
    );
}

export default Catalog;
