import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {categoriesStore, handleLoading} from "../../redux/slices/catalogSlice";
import "./ModelCatalog.scss";
import AccordionAnalogue from "./accordion-analogue";
import Lottie from "lottie-react";
import SearchForm from "../main/search-form";
import spinner from '../../assets/spinner.json';


const Category = ({ category, onItemClick, products, openCategories, setOpenCategories, selectedCategoryKey }) => {
    const hasChildCategories = category.child_categories && category.child_categories.length > 0;
    const isOpen = openCategories[category.key] || false; // Получаем состояние открытости категории
    const isSelected = category.key === selectedCategoryKey; // Проверяем, выбрана ли категория

    const toggleAccordion = () => {
        setOpenCategories((prevState) => {
            const newState = {
                ...prevState,
                [category.key]: !isOpen, // Обновляем состояние открытости категории
            };
            sessionStorage.setItem('openCategories', JSON.stringify(newState));
         //   localStorage.setItem('openCategories', JSON.stringify(newState)); // Сохраняем состояние в localStorage
            return newState;
        });
    };

    const handleItemClick = () => {
        if (!hasChildCategories) {
            onItemClick(category);
        }
    };

    return (
        <div className={`category ${isSelected ? 'selected' : ''}`}> {/* Добавляем класс, если категория выбрана */}
            <h2 className="category-title" onClick={hasChildCategories ? toggleAccordion : handleItemClick}>
                {category.name}
            </h2>
            {hasChildCategories && isOpen && (
                <ul className="subcategory-list">
                    {category.child_categories.map((childCategory) => (
                        <li key={childCategory.key}>
                            <Category
                                category={childCategory}
                                onItemClick={onItemClick}
                                products={products}
                                openCategories={openCategories}
                                setOpenCategories={setOpenCategories}
                                selectedCategoryKey={selectedCategoryKey} // Передаем ключ выбранной категории
                            />
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

const ModelCatalog = ({ url }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const {
        categories = [], // Инициализация по умолчанию
        chosenBrand,
        chosenModel,
        selectedEngine,
        isLoading,
    } = useSelector((store) => store.catalogSlice);

    const [activeKey, setActiveKey] = useState(null);
    const [selectedCategoryKey, setSelectedCategoryKey] = useState(null);
    const [products, setProducts] = useState(null);
    const [filteredProducts, setFilteredProducts] = useState(null);
    const [openCategories, setOpenCategories] = useState({});

    const path = location.pathname.split('/');
    const model = path.pop();
    const brand = path.pop();

    // Загрузка категорий
    useEffect(() => {
        const getCategories = async () => {
            dispatch(handleLoading({ isLoading: true }));
            try {
                const response = await fetch(`${url}/api/products/categories?brand=${brand}&model=${model}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                });
                const result = await response.json();

                if (result.categories && Array.isArray(result.categories)) {
                    dispatch(categoriesStore(result.categories));
                    dispatch(handleLoading({ isLoading: false }));

                  //  const lastSelectedCategoryKey = localStorage.getItem('lastSelectedCategoryKey');
                    const lastSelectedCategoryKey = sessionStorage.getItem('lastSelectedCategoryKey');

                    if (lastSelectedCategoryKey) {
                        setSelectedCategoryKey(lastSelectedCategoryKey);
                        setActiveKey(lastSelectedCategoryKey);
                        fetchProducts(lastSelectedCategoryKey);
                    } else {
                        setActiveKey(result.categories[0].key);
                    }

                   // const savedOpenCategories = localStorage.getItem('openCategories');
                    const savedOpenCategories = sessionStorage.getItem('openCategories');

                    if (savedOpenCategories) {
                        setOpenCategories(JSON.parse(savedOpenCategories));
                    }
                } else {
                    console.error("Ошибка: categories не является массивом");
                    dispatch(handleLoading({ isLoading: false }));
                }
            } catch (error) {
                console.error("Ошибка при загрузке категорий:", error);
                dispatch(handleLoading({ isLoading: false }));
            }
        };

        getCategories();
    }, [url, brand, model, dispatch]);

    // Загрузка продуктов
    const fetchProducts = async (key) => {
        dispatch(handleLoading({ isLoading: true }));
        try {
            const response = await fetch(`${url}/api/products?brand=${brand}&model=${model}&category=${key}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            });
            const result = await response.json();

            if (result.products && Array.isArray(result.products)) {
                setProducts(result.products);
            } else {
                setProducts([])
                console.error("Ошибка: products не является массивом");
            }
        } catch (error) {
            console.error("Ошибка при загрузке продуктов:", error);
        } finally {
            dispatch(handleLoading({ isLoading: false }));
        }
    };

    // Обработчик клика по категории
    const handleItemClick = (category) => {
        fetchProducts(category.key);
        setSelectedCategoryKey(category.key);

       // localStorage.setItem('lastSelectedCategoryKey', category.key);
        sessionStorage.setItem('lastSelectedCategoryKey', category.key);

        setOpenCategories((prevState) => {
            const newState = {
                ...prevState,
                [category.key]: true,
            };

            sessionStorage.setItem('openCategories', JSON.stringify(newState));
           // localStorage.setItem('openCategories', JSON.stringify(newState));

            return newState;
        });
    };

    // Фильтрация продуктов
    useEffect(() => {
        if (products && Array.isArray(products)) {
            if (selectedEngine && selectedEngine !== "Тип двигателя") {
                const filtered = products.filter((product) =>
                    product.cars.some((car) => car.engine === selectedEngine)
                );
                setFilteredProducts(filtered);
            } else {
                setFilteredProducts(products);
            }
        }
    }, [selectedEngine, products]);

    return (
        <>
            <SearchForm />
            <div className="ModelCatalog ">
                <aside className="aside-wrapper">
                    {categories && categories.length > 0 ? (
                        categories.map((rootCategory) => (
                            <Category
                                key={rootCategory.key}
                                category={rootCategory}
                                onItemClick={handleItemClick}
                                products={products}
                                openCategories={openCategories}
                                setOpenCategories={setOpenCategories}
                                selectedCategoryKey={selectedCategoryKey}
                            />
                        ))
                    ) : (
                        <p>Категории не найдены</p>
                    )}
                </aside>
                {isLoading ? (
                    <div className="lottie-wrapper">
                        <Lottie className="animation" animationData={spinner} loop={true} />
                    </div>
                ) : (
                    <div className={'products'}>
                        {filteredProducts !== null && filteredProducts.length > 0 ? (
                            filteredProducts.map((el, index) => (
                                <div key={el.id} className="product">
                                    <AccordionAnalogue analogue={el} i={el.key} />
                                </div>
                            ))
                        ) : (
                            <p>{filteredProducts !== null && 'Здесь ничего пока нет!'}</p>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default ModelCatalog;



