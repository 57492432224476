import React, { useState, useEffect } from "react";
import { YMaps, Map, Clusterer, Placemark } from "react-yandex-maps";
import Accordion from "./accordion-contacts";

const Contacts = ({ stores }) => {
    const [allStores, setAllStores] = useState(stores || []);
    const [chosenCity, setChosenCity] = useState([]);
    const [center, setCenter] = useState([47.629989, 43.146086]);
    const [zoom, setZoom] = useState(7);
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);

    // Обработчик изменения размера окна
    useEffect(() => {
        const handleResize = () => setInnerWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // Обработчик клика по городу
    const handleCityClick = (e) => {
        const { value, center, zoom } = e.target.dataset;
        const mapZoom = parseFloat(zoom);
        const mapCenter = center.split(",").map((item) => parseFloat(item));

        const updatedStores = allStores.map((store) => ({
            ...store,
            selected: store.value === value ? !store.selected : false,
        }));

        const selectedCity = updatedStores.filter((store) => store.selected);

        setAllStores(updatedStores);
        setChosenCity(selectedCity);
        setCenter(selectedCity.length > 0 ? mapCenter : [47.629989, 43.146086]);
        setZoom(selectedCity.length > 0 ? mapZoom : 7);
    };

    const handleShopClick = (shop) => {
        setCenter(shop.coords);
        setZoom(18)
    }

    // Рендер карты
    const renderMap = (mapSettings) => {
        return (
            <YMaps>
                <Map state={mapSettings} width="100%" height="100%">
                    <Clusterer
                        options={{
                            preset: "islands#invertedVioletClusterIcons",
                            groupByCoordinates: false,
                        }}
                    >
                        {(chosenCity.length > 0 ? chosenCity : allStores).map((store) =>
                            store.shops.map((shop) => (
                                <Placemark
                                    key={shop.id}
                                    geometry={shop.coords}
                                    modules={["geoObject.addon.balloon"]}
                                    properties={{
                                        balloonContent: `
                                            <h2>${store.city}</h2>
                                            <h3>${shop.title}</h3>
                                            <p>${shop.phone}</p>
                                        `,
                                    }}
                                />
                            ))
                        )}
                    </Clusterer>
                </Map>
            </YMaps>
        );
    };
    console.log('allStores', allStores)

    // Настройки карты для разных устройств
    const mapSettings = { center, zoom };
    const mapSettingsLaptop = { center, zoom: zoom - 1 };
    const mapSettingsMobile = { center, zoom: zoom - 2 };

    return (
        <section className="contacts">
            <div className="contacts-wrapper">
                <div className="contacts-menu">
                    {allStores.map((store, i) => (
                        <button
                            className={store.selected ? "active" : ""}
                            onClick={handleCityClick}
                            data-value={store.value}
                            data-zoom={store.zoom}
                            data-center={store.mapCenter}
                            key={i}
                        >
                            г. {store.city}
                        </button>
                    ))}
                </div>
                {allStores.map(
                    (store, i) =>
                        store.selected && (
                            <div className="addresses-wrapper" key={i}>
                                {store.shops.map((shop, i) => (
                                    <div className="addresses" key={i}>
                                        <p>{shop.title}</p>
                                        <p>{shop.phone}</p>
                                    </div>
                                ))}
                            </div>
                        )
                )}
            </div>
            <div className="contacts-wrapper-mobile">
                {allStores.map((store, i) => (
                    <Accordion
                        city={store.city}
                        value={store.value}
                        mapCenter={store.mapCenter}
                        zoom={store.zoom}
                        selected={store.selected}
                        shops={store.shops}
                        handleCityClick={handleCityClick}
                        handleShopClick={handleShopClick}
                        key={i}
                    />
                ))}
            </div>
            <div className="map">
                {innerWidth >= 1920 && renderMap(mapSettings)}
                {innerWidth >= 1024 && innerWidth < 1920 && renderMap(mapSettingsLaptop)}
                {innerWidth < 1024 && renderMap(mapSettingsMobile)}
            </div>
        </section>
    );
};

export default Contacts;